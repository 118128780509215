import React from "react";
import { usePageData } from "../core";

const LoadingSpinner: React.FC = () => {
  const {showLoading} = usePageData()

  return <div
    className={`position-absolute w-100 h-100 z-index-2 top-50 ${showLoading ? '' : 'd-none'}`}
    style={{left: '50%'}}
  >
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
}

export default LoadingSpinner