import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import { InitialUser, UserModel } from "../../users/models/UserModel";
import * as querystring from "querystring";

const API_URL = process.env.REACT_APP_API_URL || 'api'

const GET_USER_BY_TOKEN_URL = `${API_URL}/private/user/get-by-token`
const LOGIN_URL = `${API_URL}/user/login`
const REGISTER_URL = `${API_URL}/user/register`
const REQUEST_PASSWORD_URL = `${API_URL}/user/forgot-password`

// Server should return AuthModel
export function login(phone: string, password: string) {
  return axios.post(LOGIN_URL, querystring.stringify({phone, password}))
}

// Server should return AuthModel
export function register(fullName: string, phone: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, querystring.stringify({
    full_name: fullName,
    phone,
    password,
  }))
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(phone: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {phone})
}

export function getUserByToken(): Promise<UserModel> {
  return axios.post(GET_USER_BY_TOKEN_URL).then(response => {
    if (response.status === 200) {
      return {
        id: response.data.id,
        fullName: response.data.full_name,
        phone: response.data.phone,
        status: response.data.status,
      } as UserModel;
    } else {
      return InitialUser;
    }
  });
  // return axios.post<UserModel>(GET_USER_BY_TOKEN_URL)

}
