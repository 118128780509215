export interface UserModel {
  id: string
  userId: string
  modelId: string
  fullName: string
  phone: string
  status: string
  createdAt?: string
  updatedAt?: string
}

export interface UserDataModel {
  id: string
  userId: string
  fullName: string
  phone: string
  status: string
  password: string
  createdAt?: string
  updatedAt?: string
}

export const InitialUser:UserModel = {
  id: '',
  userId: '',
  modelId: '',
  fullName: '',
  phone: '',
  status: '',
}

export const InitialUserData:UserDataModel = {
  id: '',
  userId: '',
  fullName: '',
  phone: '',
  status: '',
  password: '',
}