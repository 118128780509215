import {useEffect, useRef} from 'react'
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from '../assets/ts/components'

import { useLayout, usePageData } from "./core";
import axios from "axios"
import { Carousel } from "bootstrap";

export function MasterInit() {
  const {config} = useLayout()
  const isFirstRun = useRef(true)
  const {setShowLoading} = usePageData()

  const pluginsInitialization = () => {
    isFirstRun.current = false
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
      SwapperComponent.bootstrap()
    }, 500)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      pluginsInitialization()
    }
    // set loading from axios request
    let startLoad: number
    axios.interceptors.request.use(
      (request)=>{
        startLoad = new Date().getTime()
        setShowLoading(true)
        return request
      }
    )
    // set loading from axios request
    axios.interceptors.response.use(
      (response)=>{
        const interval = 1000 - (new Date().getTime() - startLoad)
        if (interval > 0) {
          setTimeout(()=>{setShowLoading(false)}, interval)
        } else {
          setShowLoading(false)
        }

        return response
      },
      (error: any) => {
        setShowLoading(false)
      }
    )
    // eslint-disable-next-line
  }, [config])

  // for modal
  const carouselRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const element = carouselRef.current
    if (!element) {
      return
    }

    const carousel = new Carousel(element)
    return function cleanUp() {
      carousel.dispose()
    }
  }, [])

  return <></>
}
