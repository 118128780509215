import React from "react";
import { Toast } from "react-bootstrap-v5";
import { usePageData } from "../../core";
import "./NotifyMessage.css";

const NotifyMessage: React.FC = () => {
  const {notifyPush, setNotifyPush} = usePageData()

  const show = notifyPush.title.length > 0 && notifyPush.message.length > 0
  const closeNotify = () => {
    if (show) {
      setNotifyPush({title: '', message: '', danger: false})
    }
  }

  setTimeout(() => closeNotify(), 6000)

  return <Toast
    animation={true}
    show={show}
    className={`position-fixed fade-toast ${notifyPush.danger ? 'bg-danger' : ''}`}
    style={{zIndex: 1000, top: 80, right: 10}}
    onClose={closeNotify}
  >
    <Toast.Header>
      <strong className="mr-auto">{notifyPush.title}</strong>
    </Toast.Header>
    <Toast.Body>{notifyPush.message}</Toast.Body>
  </Toast>
}

export default NotifyMessage