export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const toFileUrl = (path: string|undefined, def?: string|undefined) => {
  if (path != null && path.length > 0) {
    return `${process.env.REACT_APP_API_URL}/uploads/files/${path}`
  } else {
    return `${process.env.PUBLIC_URL}${def}`
  }
}

export const toImageUrl = (path: string|undefined, def?: string|undefined) => {
  if (path != null && path.length > 0) {
    return `${process.env.REACT_APP_API_URL}/uploads/images/${path}`
  } else {
    return `${process.env.PUBLIC_URL}${def}`
  }
}
