import clsx from "clsx";
import React, { FC } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
// import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import { useLayout } from "../../core";
import { HeaderUserMenu } from "../../../partials";
import { Me } from "../../../helpers/UserHelpers";
import { useIntl } from "react-intl";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const TopBar: FC = () => {
  const {config} = useLayout()
  const user = Me()
  const intl = useIntl()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div className="d-flex flex-column align-items-end mx-3">
          <label className='text-muted fw-bold'>{intl.formatMessage({id: 'WELCOME'})}</label>
          <label className='text-muted fw-bold'>{user.fullName}</label>
        </div>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img alt={user.fullName} src={toAbsoluteUrl('/media/avatars/blank.png')} />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggle */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {TopBar}
